// gets json from files located at eyedock.com/json
// expects parent element to have data objects for "error" and "loading"

import ApiServices from "@/services/ApiServices";

export default {
  data: function () {
    return {
      path: "/json/",
    };
  },
  methods: {
    // file is a file in the eyedock.com/json folder
    // dataObj is the name of the data object in the parent component
    // use keys (array of keys) if the json has multiple keys, where each key in the json will be assigned to a data object with that name in the parent
    getJSON(file, dataObj = "json", keys = null) {
      this.error = false;
      this.loading = true;
      // console.log("getJSON", this.path, file);
      ApiServices.genericApiCall(this.path + file) // admin = true
        .then((response) => {
          const data = response.data;
          if (data.error) {
            this.error = true;
          } else {
            if (keys) {
              keys.forEach((key) => {
                this.$data[key] = data[key];
              });
            } else this.$data[dataObj] = data;
            // this.json = data; // assign to parent data object
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (error) this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
