import Vue from "vue";
import Vuex from "vuex";
import ApiServices from "@/services/ApiServices";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mode: "home",
    admin: null,
    userData: null,
    detail: null,
    loading: false,
    showQuickCalcs: false,
    showRxSearch: false,
    rx: { od: {}, os: {} },
    clCompanies: null,
    mainCompanyIds: [2, 4, 8, 1],
    favoriteCompanyIds: [2, 4, 8, 1],

    // tempCategory: null, // store api data for a module, so api doesn't have to be called if navigating within that module

    filterDefaults: {
      replace: "DISPOSABLES",
      type: "ANY",
      typeOS: "ANY",
      name: "",
      company: "MAIN",
    },
    filters: {
      replace: "DISPOSABLES",
      type: "ANY",
      typeOS: "ANY",
      name: "",
      company: "MAIN",
    },
  },
  getters: {
    companyIndicesById: (state) => {
      if (!state.clCompanies) return null;
      let rtn = {};
      state.clCompanies.forEach((company, i) => {
        const id = company.id.toString();
        rtn[id] = i;
      });
      return rtn;
    },
    nonFavoriteCompanyIds: (state) => {
      if (!state.clCompanies) return null;
      let rtn = [];
      state.clCompanies.forEach((company) => {
        let id = parseInt(company.id);
        if (!state.favoriteCompanyIds.includes(id)) rtn.push(id);
      });
      return rtn;
    },
  },
  mutations: {
    SET_RX(state, payload) {
      state.rx[payload.eye] = payload.rx;
    },
    SET_MODE(state, payload) {
      state.mode = payload;
    },
    SET_DETAIL(state, payload) {
      state.detail = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_ADMIN(state, payload) {
      state.admin = payload;
    },
    SET_USER_DATA(state, payload) {
      state.userData = payload;
    },
    SET_FILTER_VALUE(state, payload) {
      state.filters[payload.key] = payload.value;
    },
    SET_CL_COMPANIES(state, payload) {
      state.clCompanies = payload;
      //console.log(state.clCompanies);
    },
    TOGGLE_QUICK_CALCS(state) {
      state.showQuickCalcs = !state.showQuickCalcs;
    },
    SHOW_RX_SEARCH(state, payload) {
      state.showRxSearch = payload;
    },
    // SET_ALL_CL_DATA(state, payload) {
    //   state.allClData = payload;
    //   //console.log(state.clCompanies);
    // },
    // SET_CL_META(state, payload) {
    //   state.clMetadata = payload;
    //   //console.log(state.clCompanies);
    // },
    SET_COMP_SELECTED(state, { payload, getters }) {
      // console.log("vuex SET_COMP_SELECTED ");
      // console.log(payload);
      let index = getters.companyIndicesById[payload.id.toString()];
      state.clCompanies[index].selected = payload.selected;
    },
    // SET_TEMP_DATA(state, payload) {
    //   state.tempData = payload;
    // },
  },
  actions: {
    setRx: ({ commit }, payload) => {
      commit("SET_RX", payload);
    },
    setModeAndDetail: ({ commit }, payload) => {
      commit("SET_MODE", payload.mode);
      commit("SET_DETAIL", payload.detail);
    },
    setLoading: ({ commit }, payload) => {
      commit("SET_LOADING", payload);
    },
    setAdmin: ({ commit }, payload) => {
      commit("SET_ADMIN", payload);
    },
    setUserData: ({ commit }, payload) => {
      commit("SET_USER_DATA", payload);
    },
    setFilterValue: ({ commit }, payload) => {
      commit("SET_FILTER_VALUE", payload);
    },
    toggleShowQuickCalcs: ({ commit }) => {
      commit("TOGGLE_QUICK_CALCS");
    },
    showRxSearch: ({ commit }, payload) => {
      commit("SHOW_RX_SEARCH", payload);
    },
    // setTempData: ({ commit }, payload) => {
    //   commit("SET_TEMP_CATEGORY", payload);
    // },
    // setClCompanies: ({ commit }, payload) => {
    //   commit("SET_CL_COMPANIES", payload);
    // },
    // setAllClData: ({ commit }, payload) => {
    //   commit("SET_ALL_CL_DATA", payload);
    // },

    // loadClData({ commit }) {
    //   console.log("loading company data in store");
    //   ApiServices.initialLensDataAPI()
    //     .then((response) => {
    //       if (response.data.lenses)
    //         commit("SET_ALL_CL_DATA", response.data.lenses);
    //       if (response.data.meta) commit("SET_CL_META", response.data.meta);
    //       if (response.data.companies)
    //         commit("SET_CL_COMPANIES", response.data.companies);
    //     })
    //     .catch((e) => {
    //       console.log(e); // display it
    //     });
    // },

    loadCompanies({ commit }) {
      // ----  get cl companies right away -----
      console.log("loading companies");
      ApiServices.genericApiCall("/lens/companies")

        .then((response) => {
          commit("SET_CL_COMPANIES", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  modules: {},
});
