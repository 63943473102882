<template>
  <div class="" :class="row ? 'd-flex flex-row' : ' d-inline-block'">
    <med-icons
      v-if="listing.product_type"
      kind="rx"
      :size="18"
      :val="listing.product_type"
      :showLabel="false"
      class="mr-1"
    ></med-icons>

    <med-icons
      v-if="listing.dosage_form"
      kind="form"
      :size="18"
      :val="listing.dosage_form"
      class="mr-1"
      :showLabel="showLabels"
    ></med-icons>
    <med-icons
      v-if="!isOTC && listing.is_brand"
      kind="brand"
      :size="18"
      class="mr-1"
      :val="listing.is_brand"
      :showLabel="showLabels"
    ></med-icons>
  </div>
</template>

<script>
// import Name from "../dir/file";
import MedIcons from "./MedIcons";

export default {
  name: "MedIconBlock",
  components: {
    MedIcons,
  },
  props: {
    listing: null,
    showLabels: {
      type: Boolean,
      default: true,
    },
    row: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {};
  },
  methods: {},
  computed: {
    isOTC: function () {
      if (!this.listing.product_type) return false;
      return this.listing.product_type.toLowerCase().includes("otc");
    },
  },
};
</script>

<style scoped></style>
