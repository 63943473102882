export default {
  // parent component must contain getJSON mixin
  // and a newVersion data element
  name: "UpdateMixin",
  props: {},
  data: function () {
    return {
      currentVersion: null,
      updateObj: null,
      lastVersionCheckTime: null,
      frequency: 1, // hours
    };
  },
  methods: {
    checkForUpdate() {
      // console.log("checking for update ");
      this.getJSON("eyedock_version", "updateObj"); // in the mixin
    },
    doVersionRefresh() {
      this.$router.go();
      this.currentVersion = this.updateObj?.version;
      localStorage.setItem("eyedock_version", this.updateObj?.version);
    },
  },
  computed: {},
  watch: {
    $route() {
      // check for new app version if it hasn't been checked in a while
      let currentD = new Date();
      let lvCheck = this.lastVersionCheckTime;
      let hours = 0;
      if (lvCheck) hours = Math.abs(currentD - lvCheck) / (5 * 60 * 1000);
      if (!lvCheck || hours > this.frequency) this.checkForUpdate();
    },
    updateObj: {
      handler: function (val) {
        let currentNum = localStorage.getItem("eyedock_version");
        let newVersion = val?.version;
        if (currentNum && !this.currentVersion)
          this.currentVersion = currentNum;
        if (newVersion && !currentNum) {
          // no version was set here or in local storage
          this.currentVersion = newVersion;
          localStorage.setItem("eyedock_version", newVersion);
        } else if (currentNum < newVersion) {
          this.newVersion = true;
          // console.log("update needed", currentNum, newVersion);
        }
      },
      deep: true,
    },
  },
  mounted() {
    let currentNum = localStorage.getItem("eyedock_version");
    if (currentNum && !this.currentVersion)
      this.currentVersion = parseInt(currentNum);
  },
};
