export default {
  navTree: [
    {
      label: "Contact Lenses",
      shortLabel: "SCLs",
      to: "/cls/",
      image: "https://www.eyedock.com/images/menu_icons/scl_icon.svg",
      subtitle: "Soft CL parameters and more",
      // requireAccount: true,
      children: [
        {
          label: "Soft CLs Home",
          icon: "home",
          to: "/cls/",
        },
        {
          label: "Search By Refraction",
          image: "https://www.eyedock.com/images/menu_icons/phoropter_icon.svg",
          action: "showRxSearch",
          pro: true,
          moreInfo: "/documentation/cl-search-by-refraction",
        },
        {
          label: "---",
          sectionHeader: "Soft Lens Types",
        },
        {
          label: "Sphericals",
          image:
            "https://www.eyedock.com/images/icons/lensTypes/sphere_icon_active.svg",
          to: "/cls/sphere",
        },
        {
          label: "Torics",
          image:
            "https://www.eyedock.com/images/icons/lensTypes/toric_icon_active.svg",

          to: "/cls/toric",
        },
        {
          label: "Multifocals",
          image:
            "https://www.eyedock.com/images/icons/lensTypes/bifocal_icon_active.svg",

          to: "/cls/bifocal_sphere",
        },
        {
          label: "Multifocal Torics",
          image:
            "https://www.eyedock.com/images/icons/lensTypes/bifocal_toric_icon_active.svg",
          to: "/cls/bifocal_toric",
        },
        {
          label: "Cosmetic Colors (sphere)",
          image:
            "https://www.eyedock.com/images/icons/lensTypes/cosmetic_sphere_icon_active.svg",
          to: "/cls/cosmetic_sphere",
        },
        {
          label: "Cosmetic Colors (toric)",
          image:
            "https://www.eyedock.com/images/icons/lensTypes/cosmetic_toric_icon_active.svg",
          to: "/cls/cosmetic_toric",
        },
        {
          label: "---",
          sectionHeader: "Companies",
        },
        {
          label: "Alcon",
          icon: "factory",
          labelIsImage:
            "https://www.eyedock.com/modules/Lenses/pnimages/comp_logos/Alcon.svg",
          to: "/cls/company/alcon",
        },
        {
          label: "Bausch & Lomb",
          icon: "factory",
          labelIsImage:
            "https://www.eyedock.com/modules/Lenses/pnimages/comp_logos/bausch_lomb.svg",
          to: "/cls/company/bausch_and_lomb",
        },
        {
          label: "CooperVision",
          icon: "factory",
          labelIsImage:
            "https://www.eyedock.com/modules/Lenses/pnimages/comp_logos/Coopervision.svg",
          to: "/cls/company/cooper",
        },
        {
          label: "Johnson & Johnson",
          icon: "factory",
          labelIsImage:
            "https://www.eyedock.com/modules/Lenses/pnimages/comp_logos/Johnson__and__Johnson.svg",
          to: "/cls/company/johnson_and_johnson",
        },
        {
          label: "More Companies",
          icon: "factory",
          to: "/cls/companies",
        },

        {
          label: "---",
          sectionHeader: "More",
        },

        {
          label: "Private label CLs",
          image:
            "https://www.eyedock.com/images/menu_icons/private_label_icon.svg",
          to: "/cls/privateLabels",
        },
        {
          label: "Advanced CL Searches",
          icon: "database-search",
          to: "/cls/advanced-search",
        },

        // {
        //   label: "---",
        //   sectionHeader: "More Search Tools",
        // },
        // {
        //   label: "Private label browser",
        //   image:
        //     "https://www.eyedock.com/images/menu_icons/private_label_icon.svg",
        //   to: "/cls/privateLabels",
        // },
        // {
        //   label: "Hybrid Lenses",
        //   image: "https://www.eyedock.com/images/menu_icons/hybrid_icon.svg",
        //   to: "/hybrids",
        // },
      ],
    },
    {
      label: "GP",
      shortLabel: "GPs",
      to: "/rgps",
      image: "https://www.eyedock.com/images/menu_icons/scleral2_icon.svg",
      subtitle: "GPs & Specialty CLs",
      pro: true,
      children: [
        {
          label: "Corneal Lenses",
          image: "https://www.eyedock.com/images/menu_icons/rgp_icon.svg",
          to: "/rgps/corneal",
        },
        {
          label: "Scleral Lenses",
          image: "https://www.eyedock.com/images/menu_icons/scleral_icon.svg",
          to: "/rgps/scleral",
        },
        {
          label: "Ortho-K / CRT",
          image: "https://www.eyedock.com/images/menu_icons/ortho_k_icon.svg",
          to: "/rgps/ortho-k",
        },
        {
          label: "Hybrid Lenses",
          image: "https://www.eyedock.com/images/menu_icons/hybrid2_icon.svg",
          to: "/hybrids",
        },
        {
          label: "---",
          sectionHeader: "Indications",
        },
        {
          label: "Presbyopia",
          image:
            "https://www.eyedock.com/images/menu_icons/rgp_bifocal_icon.svg",
          to: "/rgps/tag/presbyopia",
        },
        {
          label: "Irregular Corneas",
          image:
            "https://www.eyedock.com/images/menu_icons/topography_icon.svg",
          to: "/rgps/tag/irregular-cornea",
        },

        {
          label: "---",
          sectionHeader: "More",
        },

        {
          label: "Specialty Lens Companies",
          icon: "factory",
          to: "/rgps/companies",
        },
        {
          label: "GP Materials",
          icon: "flask-empty-outline",
          to: "/rgps/materials",
        },
      ],
    },

    {
      label: "Hybrid Lenses",
      image: "https://www.eyedock.com/images/menu_icons/hybrid_icon.svg",
      to: "/hybrids",
      subtitle: "SynergEyes lenses",
      pro: false,
      only: "xs",
    },
    {
      label: "Search SCLs By Rx",
      image: "https://www.eyedock.com/images/menu_icons/phoropter_icon.svg",
      action: "showRxSearch",
      pro: true,
      moreInfo: "/documentation/cl-search-by-refraction",
      only: "xs",
    },

    {
      label: "Meds",
      shortLabel: "Meds",
      to: "/meds",
      image: "https://www.eyedock.com/images/menu_icons/meds_icon.svg",
      subtitle: "Search over 1,000 ophthalmic drugs and OTC products. ",
      // requireAccount: true,
      children: [
        {
          label: "Meds Home",
          icon: "home",
          to: "/meds/",
        },

        { label: "Allergy", to: "/meds/category/allergy" },
        { label: "Anesthetic", to: "/meds/category/anesthetic" },
        { label: "Antibiotic", to: "/meds/category/antibiotic" },
        { label: "Antibiotic/Corticosteroid", to: "/meds/category/combo" },
        { label: "Antifungal", to: "/meds/category/antifungal" },
        { label: "Antiviral", to: "/meds/category/antiviral" },
        { label: "Corticosteroid", to: "/meds/category/corticosteroid" },
        {
          label: "Mydriatic/Cycloplegic",
          to: "/meds/category/cyclo_mydriatic",
        },
        { label: "Dyes", to: "/meds/category/dye" },
        { label: "Glaucoma", to: "/meds/category/glaucoma" },
        { label: "Homeopathic", to: "/meds/category/homeopathic" },
        { label: "Hypertonicity", to: "/meds/category/hypertonicity" },
        { label: "Immunomodulator", to: "/meds/category/immunomodulator" },
        { label: "Lubricant", to: "/meds/category/lubricant" },
        { label: "NSAID", to: "/meds/category/NSAID" },
        { label: "Vasoconstrictor", to: "/meds/category/vasoconstrictor" },
        { label: "Other", to: "/meds/category/other" },
        {
          label: "Systemic Meds",
          to: "/meds/orals",
          icon: "pill",
        },
        {
          label: "Cap Colors",
          to: "/meds/bottle-cap-colors",
          image: "https://www.eyedock.com/images/meds/bottle_red.svg",
        },
        {
          label: "Companies",
          to: "/meds/companies",
          icon: "factory",
        },
        {
          label: "Ingredients",
          to: "/meds/ingredients",
          icon: "flask-outline",
        },
        {
          label: "MOAs",
          shortLabel: "MOAs",
          to: "/meds/moas",
          icon: "auto-fix",
        },
      ],
    },
    {
      label: "ICD & Billing Codes",
      shortLabel: "Codes",
      to: "/icd10",
      image: "https://www.eyedock.com/images/menu_icons/icd_icon.svg",
      subtitle: "Quickly search 70,000 ICD10 Codes",
      only: "sm",
      children: [
        // {
        //   label: "---",
        //   sectionHeader: "Billing Protocols",
        // },
        // {
        //   label: "Billing/Treatment Protocols",
        //   to: "/billing-protocols",
        //   icon: "repeat",
        // },

        {
          label: "---",
          sectionHeader: "Billing Codes",
          nonproNotes: [
            "Search and browse eye-related E/M &amp; CPT codes (<a href='/documentation/billing-codes-em-and-cpt'>more info</a>)",
          ],
          image: "https://www.eyedock.com/images/menu_icons/em_icon.svg",
          pro: true,
        },

        {
          label: "Evaluation & Management (E/M)",
          pro: true,
          nonproHide: true,
          to: "/billing-codes#category=em",
          image: "https://www.eyedock.com/images/menu_icons/em_icon.svg",
          moreInfo: "/documentation/billing-codes-em-and-cpt",
        },
        {
          label: "Procedures (CPT)",
          pro: true,
          nonproHide: true,
          to: "/billing-codes#category=cpt",
          image: "https://www.eyedock.com/images/menu_icons/cpt_icon.svg",
        },
        {
          label: "Modifiers",
          pro: true,
          nonproHide: true,
          to: "/billing-codes#category=modifiers",
          image: "https://www.eyedock.com/images/menu_icons/modifier_icon.svg",
        },
        {
          label: "---",
          sectionHeader: "ICD 10 Codes",
          nonproNotes: [
            "Search and browse 70,000 ICD10 codes (<a href='/documentation/icd10-codes'>more info</a>)",
            "Diabetes Coding Widget (<a href='/documentation/diabetic-retinopathy-coding-widget'>more info</a>)",
          ],
          icon: "sitemap",
          pro: true,
        },
        {
          label: "ICD Codes Home",
          pro: true,
          nonproHide: true,
          icon: "home",
          to: "/icd10/",
          moreInfo: "/documentation/icd10-codes",
        },

        {
          label: "Diabetes ICD Widget",
          pro: true,
          nonproHide: true,
          color: "#fde3e3",
          icon: "sitemap",
          to: "/icd10/diabetic-retinopathy-widget",
          moreInfo: "/documentation/diabetic-retinopathy-coding-widget",
        },
        {
          label: "Refractive error & Accommodation",
          pro: true,
          nonproHide: true,
          to: "/icd10/H52?title=Refractive%20%26%20Accom.",
        },
        {
          label: "Other vision problems",
          pro: true,
          nonproHide: true,
          to: "/icd10/H53,H54?title=Vision%20Problems",
        },
        {
          label: "Headaches & migraines",
          pro: true,
          nonproHide: true,
          to: "/icd10/R51,G44,G43?title=Headache%2FMigraine",
        },
        {
          label: "Eyelid  disorders",
          pro: true,
          nonproHide: true,
          to: "/icd10/H00-H03,H57.8?title=Eyelids",
        },
        {
          label: "Lacrimal - Dry eye",
          pro: true,
          nonproHide: true,
          to: "/icd10/H04.12?title=Dry%20Eye",
        },
        {
          label: "Lacrimal - Other",
          pro: true,
          nonproHide: true,
          to: "/icd10/H04?title=Lacrimal",
        },
        {
          label: "Conjunctival disorders",
          pro: true,
          nonproHide: true,
          to: "/icd10/H10-H11?title=Conjunctiva",
        },
        {
          label: "Sclera & episclera disorders",
          pro: true,
          nonproHide: true,
          to: "/icd10/H15?title=Sclera%2FEpisclera",
        },
        {
          label: "Cornea disorders",
          pro: true,
          nonproHide: true,
          to: "/icd10/H16-H18,S05.0,T15.0?title=Cornea",
        },
        {
          label: "Iris & pupil disorders",
          pro: true,
          nonproHide: true,
          to: "/icd10/H19-H22,H57.0?title=Iris%2FPupil",
        },
        {
          label: "Lens disorders (cataracts)",
          pro: true,
          nonproHide: true,
          to: "/icd10/H25-H28?title=Lens%20%28Cataract%29",
        },
        {
          label: "Retina and Choroid disorders",
          pro: true,
          nonproHide: true,
          to: "/icd10/H30-H36?title=Retina%20%26%20Choroid",
        },
        {
          label: "Diabetic retinopathy",
          pro: true,
          nonproHide: true,
          to: "/icd10/diabetes?title=Diabetic%20Retinopathy",
        },
        {
          label: "Vitreous disorders",
          pro: true,
          nonproHide: true,
          to: "/icd10/H43?title=Vitreous",
        },
        {
          label: "Optic nerve and visual pathway disorders",
          pro: true,
          nonproHide: true,
          to: "/icd10/H46-H47?title=Optic%20Nerve",
        },
        {
          label: "Nevi",
          pro: true,
          nonproHide: true,
          to: "/icd10/D31,C69?title=Nevi",
        },
        {
          label: "Glaucoma",
          pro: true,
          nonproHide: true,
          to: "/icd10/H40-H42?title=Glaucoma",
        },
        {
          label: "Postoperative complications",
          pro: true,
          nonproHide: true,
          to: "/icd10/H59?title=Post-operative",
        },
      ],
    },
    // {
    //   label: "Billing/Treatment Protocols",
    //   shortLabel: "Protocols",
    //   to: "/billing-protocols",
    //   pro: true,
    //   icon: "repeat",
    //   subtitle: "Treatment and billing scenarios",
    //   only: "xs",
    // },
    {
      label: "Billing Codes",
      shortLabel: "EM CPT",
      to: "/billing-codes",
      pro: true,
      moreInfo: "/documentation/icd10-codes",
      image: "https://www.eyedock.com/images/menu_icons/icd_icon.svg",
      subtitle: "E/M Codes, CPT Codes, and Modifiers",
      only: "xs",
    },
    {
      label: "ICD 10Codes",
      shortLabel: "ICD10",
      to: "/icd10",
      pro: true,
      moreInfo: "/documentation/icd10-codes",
      image: "https://www.eyedock.com/images/menu_icons/icd_icon.svg",
      subtitle: "Quickly search 70,000 ICD10 Codes",
      only: "xs",
    },

    {
      label: "Clinical References",
      shortLabel: "Refs",
      icon: "book-open-variant",
      to: "/clinical-references",
      // requireAccount: true,
      pro: false,
      only: "sm",
      children: [
        {
          label: "Ocular Conditions",
          icon: "eye-outline",
          to: "/ocular-conditions",
          moreInfo: "/documentation/ocular-conditions",
          pro: true,
        },
        {
          label: "Clinical References",
          icon: "table",
          to: "/clinical-references",
          pro: false,
        },
        {
          label: "Educational Images",
          icon: "image",
          to: "/educational-images",
          pro: false,
        },
      ],
    },

    {
      label: "Clinical References",
      icon: "table",
      to: "/clinical-references",
      moreInfo: "documentation/clinical-references",
      pro: false,
      // requireAccount: true,
      subtitle: "Expected values & grading scales",
      only: "xs",
    },
    {
      label: "Patient Education Images",
      icon: "image",
      to: "/educational-images",
      moreInfo: "documentation/education-images",
      pro: false,
      // requireAccount: true,
      subtitle: "Eye and optics illustrations",
      only: "xs",
    },
    {
      label: "Ocular Conditions",
      shortLabel: "Dz",
      to: "/ocular-conditions",
      icon: "eye-outline",
      pro: true,
      // requireAccount: true,
      moreInfo: "/documentation/ocular-conditions",
      subtitle: "Signs, symptoms, and treatments",
      only: "xs",
      // children: [
      //   {
      //     label: "Ocular Conditions Home",
      //     icon: "home",
      //     to: "/ocular-conditions",
      //   },
      //   {
      //     label: "Lids & Lashes",
      //     to: "/ocular-conditions/category/eyelid-eyelashes",
      //   },
      //   {
      //     label: "Orbit",
      //     to: "/ocular-conditions/category/orbit",
      //   },
      //   {
      //     label: "Conjunctiva",
      //     to: "/ocular-conditions/category/conjunctiva",
      //   },
      //   {
      //     label: "Episclera",
      //     to: "/ocular-conditions/category/episclera",
      //   },
      //   {
      //     label: "Sclera",
      //     to: "/ocular-conditions/category/sclera",
      //   },
      //   {
      //     label: "Cornea",
      //     to: "/ocular-conditions/category/cornea",
      //   },
      //   {
      //     label: "Iris",
      //     to: "/ocular-conditions/category/iris",
      //   },
      //   {
      //     label: "Anterior Chamber",
      //     to: "/ocular-conditions/category/anterior-chamber",
      //   },
      //   {
      //     label: "Lens",
      //     to: "/ocular-conditions/category/lens",
      //   },
      //   {
      //     label: "Post Surgical Complications",
      //     to: "/ocular-conditions/category/post-surgical-complications",
      //   },
      //   {
      //     label: "Contact Lens Complications",
      //     to: "/ocular-conditions/category/contact-lens-complications",
      //   },
      //   {
      //     label: "Vitreous",
      //     to: "/ocular-conditions/category/vitreous",
      //   },
      //   {
      //     label: "Optic Nerve",
      //     to: "/ocular-conditions/category/optic-nerve",
      //   },
      //   {
      //     label: "Glaucoma",
      //     to: "/ocular-conditions/category/glaucoma-ocular-conditions",
      //   },
      //   {
      //     label: "Macula",
      //     to: "/ocular-conditions/category/macula",
      //   },
      //   {
      //     label: "Retina - Vascular",
      //     to: "/ocular-conditions/category/retina-vascular",
      //   },
      //   {
      //     label: "Retina - Infectious",
      //     to: "/ocular-conditions/category/retina-infectious",
      //   },
      //   {
      //     label: "Retina - Inflammatory",
      //     to: "/ocular-conditions/category/retina-inflammatory",
      //   },
      //   {
      //     label: "Retina - Periphery",
      //     to: "/ocular-conditions/category/retina-periphery",
      //   },
      //   {
      //     label: "Choriod",
      //     to: "/ocular-conditions/category/choriod",
      //   },
      //   {
      //     label: "Neuro-ophthalmic",
      //     to: "/ocular-conditions/category/neuro-ophthalmic",
      //   },
      //   {
      //     label: "Trauma",
      //     to: "/ocular-conditions/category/trauma",
      //   },
      // ],
    },
    {
      label: "Tools",
      shortLabel: "Tools",
      to: "/calcs",
      icon: "calculator",
      subtitle: "Optics calculators and other utilities",
      // requireAccount: true,
      // moreInfo: "/documentation/calculators",
      children: [
        {
          label: "Tools & Calculators Home",
          icon: "home",
          to: "/calcs/",
        },
        {
          label: "Search CLs By Refraction",
          image: "https://www.eyedock.com/images/menu_icons/phoropter_icon.svg",
          action: "showRxSearch",
          pro: true,
          moreInfo: "/documentation/cl-search-by-refraction",
        },
        {
          label: "Contact Lens Calculator",
          image: "https://www.eyedock.com/images/menu_icons/cl_calc_icon.svg",
          // href: "https://www.eyedock.com/calcs/cl-calculator",
          to: "/calcs/cl-calculator",
          pro: true,
          moreInfo: "/documentation/cl-calculator",
        },
        {
          label: "Oblique Crossed Cylinders",
          image: "https://www.eyedock.com/images/menu_icons/oblique_icon.svg",
          // href: "https://www.eyedock.com/calcs/oblique",
          to: "/calcs/oblique",
          pro: true,
          moreInfo: "/documentation/oblique-cross-cyls",
        },
        {
          label: "Vertex & Keratometry Conversions",
          image: "https://www.eyedock.com/images/menu_icons/vertex.svg",
          // href: "https://www.eyedock.com/calcs/oblique",
          to: "/calcs/vertex",
          pro: false,
          moreInfo: "/documentation/quick-calcs",
        },
        {
          label: "CRT Lens Selector",
          image: "https://www.eyedock.com/images/menu_icons/crt_icon.svg",
          // href: "https://www.eyedock.com/calcs/oblique",
          to: "/calcs/crt",
          pro: true,
          moreInfo: "/documentation/crt",
        },
        {
          label: "CL Comparisons",
          image:
            "https://www.eyedock.com/images/menu_icons/cl_compare_icon.svg",
          // href: "https://www.eyedock.com/calcs/oblique",
          to: "/calcs/cl-compare",
          pro: true,
          // moreInfo: "/documentation/crt",
        },

        {
          label: "Meridian Power Calculator",
          // href: "https://www.eyedock.com/calcs/meridian-power-calculator",
          to: "/calcs/meridian-power-calculator",
          image:
            "https://www.eyedock.com/images/menu_icons/meridian_calc_icon.svg",
          pro: true,
          moreInfo: "/documentation/meridian-power-calculator",
        },

        {
          label: "Parks Three Step Utility",
          // href: "https://www.eyedock.com/calcs/parks-3-step",
          to: "/calcs/parks-3-step",
          image:
            "https://www.eyedock.com/images/menu_icons/parks_3_step_icon.svg",
          pro: true,
          moreInfo: "/documentation/parks-3-step",
        },
        {
          label: "Diabetic Retinopathy Coding Widget",
          to: "/icd10/diabetic-retinopathy-widget",
          image: "https://www.eyedock.com/images/menu_icons/widget_icon.svg",
          pro: true,
          moreInfo: "/documentation/icd10-codes",
        },

        {
          label: "Plaquenil Risk Calculator",
          // href: "https://www.eyedock.com/calcs/plaquenil-calcs",
          to: "/calcs/plaquenil-calcs",
          image: "https://www.eyedock.com/images/menu_icons/plaquenil_icon.svg",
          pro: false,
          moreInfo: "/documentation/plaquenil-risk-calculator",
        },
      ],
    },
  ],
};
