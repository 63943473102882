<template>
  <div class="my-2">
    <div
      class="mx-2 mx-sm-4 flex-grow-1"
      :class="{ overflow: $vuetify.breakpoint.mdAndUp }"
    >
      <v-skeleton-loader
        v-if="loading"
        type="article,article,article,article, actions"
      ></v-skeleton-loader>
      <div v-if="article">
        <div v-if="showHeader" class="heading">
          <div class="text-h4">{{ article.name }}</div>
          <v-divider class="mb-6"></v-divider>
        </div>

        <v-btn
          class="float-right ma-2"
          color="#ffc585"
          depressed
          v-if="showSubscribeButton && !isSubscribed"
          to="/documentation/about-eyedock-pro"
        >
          Upgrade to EyeDock Pro
        </v-btn>

        <vue-markdown
          :id="'md_' + articleSlug"
          v-if="processedArticle"
          :class="{ smallMdContent: !$vuetify.breakpoint.mdAndUp }"
          class="mdContent"
          >{{ processedArticle }}
        </vue-markdown>
      </div>
    </div>
  </div>
</template>

<script>
import ApiServices from "@/services/ApiServices";
import VueMarkdown from "@adapttive/vue-markdown";
import UserMixin from "@/assets/js/UserMixin";

export default {
  name: "Page",
  components: { VueMarkdown },
  mixins: [UserMixin],
  props: {
    articleSlug: null,
    showHeader: { type: Boolean, default: true },
    // use prop or import usermixin in this component?
    // isSubscribed: { type: Boolean, default: false },
    showSubscribeButton: { type: Boolean, default: false },
  },
  data: function () {
    return {
      loading: null,
      article: null,
      pageTitle: null,
      error: null,
    };
  },
  methods: {
    getArticle() {
      if (!this.articleSlug) return;
      this.loading = true;
      ApiServices.article(this.articleSlug) // admin = true
        .then((response) => {
          // this.data = response.data;
          if (response.data.article) {
            this.pageTitle = response.data.article.name;
            this.$emit("pageTitle", response.data.article.name);
            this.article = response.data.article;
            this.error = null;
          } else {
            this.error = "Article data not returned";
          }
        })
        .catch((error) => {
          console.log("error", error);
          //   this.data = null;
          if (error) this.error = "An API error occurred";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    processedArticle: function () {
      if (!this.article?.article) return null;
      let article = this.article.article;

      // add a width to images if provided in markdown file
      // example) myImage.png#width300
      article = article.replace(
        /!\[(.*)\]\((.*)#width(\d{2,3})\)/gim,
        '<img src="$2" alt="$1" style="width:$3px; max-width: 95vw">'
      );

      let nonProContent = this.isSubscribed ? "" : "$1";
      article = article.replace(/<nonpro>(.*?)<\/nonpro>/gim, nonProContent);

      let proContent = this.isSubscribed ? "$1" : "";
      article = article.replace(/<pro>(.*?)<\/pro>/gim, proContent);

      return article;
    },
  },
  watch: {
    articleSlug() {
      this.getArticle();
    },
  },
  mounted() {
    if (this.articleSlug) this.getArticle();
  },
};
</script>

<style>
.mdContent {
  max-width: 100vw;
}
.smallMdContent >>> img {
  max-width: 95% !important;
}
.smallMdContent >>> iframe {
  max-width: 95% !important;
}
.mdContent img {
  margin: 0.5em 1em 2em 0.5em;
}
</style>
