<template>
  <v-app>
    <eyedock-header class="d-print-none"></eyedock-header>

    <v-navigation-drawer
      app
      fixed
      clipped
      v-model="drawer"
      scroll-off-screen
      class="d-print-none"
      v-if="!hideNavbar"
      :width="220"
    >
      <!-- <v-btn @click="pinIt = !pinIt" x-small oval depressed primary
        >Quick Calcs</v-btn
      > -->
      <sidebar-menu @browseTitle="browseTitle = $event"></sidebar-menu>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="ma-0 pa-0">
        <div class="d-flex flex-column flex-md-row width100p">
          <div class="d-flex flex-column flex-grow-1">
            <v-slide-y-transition>
              <quick-calcs v-if="showQuickCalcs"></quick-calcs>
            </v-slide-y-transition>
            <v-btn
              @click="drawer = !drawer"
              class="d-lg-none my-2 mr-2 d-print-none"
              color="#ffc46c"
              v-if="!hideNavbar"
              depressed
            >
              <v-icon class="mr-3">mdi-chevron-down</v-icon>
              <!-- <v-icon>mdi-menu-right</v-icon> -->
              {{ browseTitle }}
              <v-icon class="ml-3">mdi-menu</v-icon>
            </v-btn>
            <router-view
              :key="$route.fullPath"
              class="mainContentClass ma-2"
            ></router-view>
          </div>
          <div
            v-if="!hideAd"
            :numberOfAds="6"
            class="d-print-none text-center mx-auto pb-2"
            :class="
              $vuetify.breakpoint.mdAndUp ? 'sideBarClass' : 'bottomClass'
            "
          >
            <ads-stack :adWidth="235"></ads-stack>
          </div>
        </div>
      </v-container>
    </v-main>
    <v-snackbar
      style="width: 100px; z-index: 999999"
      bottom
      left
      :value="newVersion"
      :timeout="-1"
      color="primary"
      :width="$vuetify.breakpoint.smAndUp ? '200' : '100%'"
    >
      <!-- Uses updateMixin methods & data -->
      <div>
        We've made some updates! Refreshing this page will ensure your using the
        most up-date-code and features.
      </div>
      <div class="d-flex flex-row">
        <v-spacer> </v-spacer>
        <v-btn outlined @click="doVersionRefresh()" class="my-2 mx-2">
          Refresh
        </v-btn>
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import EyedockHeader from "@/components/headerStuff/EyedockHeader.vue";
import SidebarMenu from "./components/headerStuff/SidebarMenu.vue";
import UserMixin from "@/assets/js/UserMixin";
import UpdateMixin from "@/assets/js/UpdateMixin";
import GetJsonMixin from "@/assets/js/GetJsonMixin";

// import UpdateMixin from "@/assets/js/UpdateMixin";
import { mapState } from "vuex";
import { version } from "../package.json";

export default {
  name: "App",

  components: {
    EyedockHeader,
    // InlineAd: () => import("@/components/universalStuff/InlineAd.vue"),
    AdsStack: () => import("@/components/universalStuff/AdsStack.vue"),
    QuickCalcs: () => import("@/components/calcsStuff/QuickCalcs.vue"),
    SidebarMenu,
  },
  mixins: [UserMixin, GetJsonMixin, UpdateMixin],

  data: () => ({
    drawer: null,
    browseTitle: "Browse Categories",
    fab: false,
    currentModule: null,
    version: version, // this is the version from package.json
    newVersion: false,
    // updateObj: null,
    // lastVersionCheckTime: null,
    // pinIt: false,
  }),
  computed: {
    ...mapState(["showQuickCalcs"]),
    hideNavbar: function () {
      // console.log(this.$route);
      return (
        this.$route.meta?.hideNavbar ||
        (this.$route.meta?.hideNavbarOnMobile &&
          !this.$vuetify.breakpoint.smAndUp)
      );
    },
    hideAd: function () {
      // console.log(this.$route);
      return this.$route.meta?.hideAd; // || this.isSubscribed;
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },

    // checkForUpdate() {
    //   console.log("checking for update ");
    //   this.getJSON("eyedock_version", "updateObj"); // in the mixin
    // },
  },
  watch: {
    showQuickCalcs(val) {
      if (val) this.toTop();
    },
    // updateObj(val) {
    //   console.log("updateObj", val);
    // },
    // $route() {
    //   // check for new app version if it hasn't been checked in a while
    //   let currentD = new Date();
    //   let lvCheck = this.lastVersionCheckTime;
    //   let hours = 0;
    //   if (lvCheck) hours = Math.abs(currentD - lvCheck) / (60 * 60 * 1000);
    //   if (!lvCheck || hours > 0.1) this.checkForUpdate();
    // },
  },

  mounted: function () {
    console.log("Package version:", this.version);
  },
  created: function () {},

  metaInfo() {
    return {
      title: "EyeDock",
      meta: [
        {
          name: "description",
          content:
            "Search and browse for contact lenses, medications related to eye care, ICD codes, and more.",
        },
        { property: "og:title", content: "EyeDock" },
        { property: "og:site_name", content: "EyeDock" },
        { property: "og:type", content: "website" },
        { property: "fb:app_id", content: "314391600718" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style>
/* .sidebar {
  max-width: 250px;
} */
.adSidebar {
  min-height: 300px;
}
.sideBarClass {
  width: 270px;
  margin: 1em 10px 10px;
  border-left: 1px solid #e7e7e7;
}
.bottomClass {
  width: 100%;
}
.mainContentClass {
  min-height: 800px;
}
</style>
