<template>
  <div>
    <v-menu
      left
      bottom
      close-on-click
      ref="menu"
      max-height="90vh"
      class="overflow-y-auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" ref="menuButton">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <hamburger-menu :navData="navData"></hamburger-menu>
    </v-menu>
  </div>
</template>

<script>
import HamburgerMenu from "@/components/headerStuff/HamburgerMenu";

// ! NOTE !!! - when changing this file you may also need to change the SegmentedButton.vue file!!!

export default {
  name: "Hamburger",
  components: {
    HamburgerMenu,
  },
  props: { navData: null },
  data: function () {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
