<template>
  <div class="d-flex flex-row align-center">
    <img
      :src="require('@/assets/images/' + nameAndSource.img)"
      v-if="nameAndSource.img"
      :style="iconStyle"
      class="mx-1"
    />
    <!-- below is just a spacer in case there's no image -->
    <div
      class="mx-1 caption"
      v-if="!nameAndSource.img && nameAndSource.label"
      :style="iconStyle"
    ></div>
    <div
      class="caption mx-1"
      :class="{ 'order-first': labelFirst }"
      v-if="nameAndSource.label && this.showLabel"
    >
      {{ toTitleCase(nameAndSource.label) }}
    </div>
  </div>
</template>

<script>
// import Name from "../dir/file";
import MedTypeParserMixin from "@/assets/js/meds/MedTypeParserMixin";

export default {
  name: "MedIcons",
  mixins: [MedTypeParserMixin],
  props: {
    kind: {
      type: String,
      validator: function (value) {
        // The value must match one of these strings
        return ["rx", "brand", "form"].indexOf(value) !== -1;
      },
    },
    val: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    labelFirst: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 22,
    },
    labelOveride: null,
  },
  data: function () {
    return {
      //path: "./",
    };
  },
  methods: {
    toTitleCase(str) {
      // todo - put this in a utility folder
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    brandStuff() {
      let img = null;
      let label = null;
      if (this.val) {
        let is_brand = parseInt(this.val) === 1; // val should be is_brand
        let is_generic = parseInt(this.val) === 0;
        if (is_brand) {
          img = "brand";
          label = "Brand";
        }
        if (is_generic) {
          img = "generic";
          label = "Generic";
        }
      }

      return { img: img, label: label };
    },
    rxStuff() {
      let img = null;
      let label = this.val.toLowerCase(); // "Human prescription drug" or "Human OTC"
      // let is_rx = label.includes("prescription");
      // let is_otc = label.includes("otc");
      let medType = this.medTypeSummary(label); // from the mixin
      if (medType.isRx) {
        img = "rx";
        label = "Prescription";
      } else if (medType.isOtc) {
        img = "otc";
        label = "OTC Drug";
      }
      return { img: img, label: label };
    },

    formStuff() {
      let img = null;
      let label = this.val.toLowerCase(); // "Human prescription drug" or "Human OTC"
      // let is_solution = label.includes("solution");
      // let is_drop = label.includes("drop");
      // let is_liquid = label.includes("liquid");
      // let is_suspension = label.includes("suspension");
      // let is_emulsion = label.includes("emulsion");
      // let is_ointment= label.includes("ointment");
      // let is_gel = label.includes("gel");
      // let is_inject = label.includes("inject");
      let medForm = this.medFormSummary(label);
      if (medForm.isDrop) img = "drop";
      // if (is_suspension) img = "suspension";
      if (medForm.isGel) img = "gel";
      if (medForm.isUng) img = "ointment";
      if (medForm.isSpray) img = "spray";
      //todo - handle if both injection and something else (inject + suspension)
      if (medForm.isInjection) img = "injection";
      label = label.replace(/(.*)\s?\/\s?Drops/gim, "$1"); // handle "Solution/Drops" -> "Solution"
      return { img: img, label: label };
    },
  },
  computed: {
    nameAndSource: function () {
      if (this.labelOveride) return this.labelOveride;
      let rtn = { name: null, img: null };
      if (this.kind == "rx") rtn = this.rxStuff();
      if (this.kind == "brand") rtn = this.brandStuff();
      if (this.kind == "form") rtn = this.formStuff();
      if (rtn.img) rtn.img = `small_${rtn.img}_icon.svg`;
      return rtn;
    },
    iconStyle: function () {
      return `width:${this.size}px`;
    },
  },
};
</script>

<style scoped></style>
