<template>
  <div class="d-flex flex-row">
    <div v-if="data && data.children">
      <v-menu
        offset-y
        :open-on-hover="!openWithClick"
        v-model="showMenu"
        open-delay="100"
        max-height="90vh"
        class="overflow-y-auto"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-if="data.label && (data.to || data.href)"
            color="primary "
            text
            small
            :to="openWithClick ? null : data.to"
            :href="openWithClick ? null : data.href"
            v-bind="attrs"
            v-on="on"
            @click="showMenu = false"
            class="px-1 mr-2"
            :class="{ 'mr-2': !condense }"
          >
            <v-img
              v-if="data.image"
              :src="data.image"
              contain
              :max-width="condense ? 24 : 28"
              max-height="24"
            ></v-img>
            <v-icon v-if="data.icon" class="mx-1" small
              >mdi-{{ data.icon }}</v-icon
            >
            <span v-if="$vuetify.breakpoint.xlAndUp"> {{ data.label }}</span>
            <span v-else-if="data.shortLabel && !condense">
              {{ data.shortLabel }}</span
            >
          </v-btn>
        </template>

        <!-- at the root: if pro feature and NOT subscribed show a card instead of the menu -->
        <v-card v-if="isPro && !isSubscribed" width="200" height="200">
          <v-card-text>
            <p class="font-weight-bold">
              {{ data.label }} is an EyeDock Pro feature.
            </p>
            <p v-if="data.moreInfo">
              <router-link :to="{ path: data.moreInfo }"
                >Learn more about {{ data.label }}</router-link
              >
            </p>
            <p>
              <router-link :to="{ path: '/documentation/about-eyedock-pro' }"
                >Learn more about EyeDock Pro.</router-link
              >
            </p>
          </v-card-text>
        </v-card>
        <!-- end show not subscribed dialog -->

        <!-- if not pro feature or IS subscribed show the menu item-->
        <v-list v-else nav dense class="overflow-y-auto scrollHeight">
          <span v-for="(item, index) in data.children" :key="index">
            <!-- menu headers -->
            <span v-if="item.label == '---'">
              <v-divider></v-divider>
              <v-subheader>{{ item.sectionHeader }}</v-subheader>
              <v-list-item v-if="!itemAuth(item)">
                <v-list-item-icon>
                  <v-img v-if="item.image" :src="item.image" width="24"></v-img>
                  <v-icon v-if="item.icon" color="primary"
                    >mdi-{{ item.icon }}</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="red--text caption"
                    >{{ item.sectionHeader }} Requires EyeDock
                    Pro</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    v-for="(note, xx) in item.nonproNotes"
                    :key="`${index}_${xx}`"
                    v-html="note"
                  ></v-list-item-subtitle> </v-list-item-content
              ></v-list-item>
            </span>

            <v-list-item
              v-else-if="
                itemAuth(item) || (!itemAuth(item) && !item.nonproHide)
              "
              link
              :style="makeStyle(item)"
              :to="itemTo(item)"
              :disabled="itemDisable(item)"
              :href="itemHref(item)"
              :two-line="!itemAuth(item)"
              @click="item.action ? doAction(item) : null"
            >
              <v-list-item-icon>
                <v-img v-if="item.image" :src="item.image" width="24"></v-img>
                <v-icon v-if="item.icon" color="primary"
                  >mdi-{{ item.icon ? item.icon : "circle-small" }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :color="item.color ? item.color : null">
                  <v-img
                    v-if="item.labelIsImage"
                    :src="item.labelIsImage"
                    height="15"
                    contain
                    :alt="item.label"
                    position="left center"
                  ></v-img>

                  <span v-else>{{ item.label }}</span>
                  <span
                    v-if="!itemAuth(item) && item.moreInfo"
                    class="blue--text"
                  >
                    - Learn more</span
                  >
                  <!-- <v-chip v-if="item.pro" color="red" x-small outlined>pro</v-chip> -->
                  <!-- <sup v-if="item.pro" class="red--text"> </sup> -->
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="!itemAuth(item)"
                  class="caption red--text"
                  >Requires EyeDock Pro
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- if there are submenu options -->
            </v-list-item>
          </span>
        </v-list>
      </v-menu>
    </div>
    <!-- if there are no sub-items just show a button -->
    <v-btn
      v-else-if="data.label && (data.to || data.href)"
      color="primary "
      text
      :to="data.to"
      :href="data.href"
    >
      <v-img
        v-if="data.image"
        :src="data.image"
        width="27"
        class="mx-1"
      ></v-img>
      {{
        data.shortLabel && !$vuetify.breakpoint.mdAndUp
          ? data.shortLabel
          : data.label
      }}</v-btn
    >

    <!-- <v-dialog v-model="dialog" width="500"> Hello </v-dialog> -->
  </div>
</template>

<script>
// import Name from "../dir/file";
// import UserMixin from "@/assets/js/UserMixin";

// ! NOTE !!! - when changing this file you may also need to change the hamburger.vue file!!!

export default {
  name: "SegmentedButton",
  mixins: [
    // UserMixin
  ],
  props: {
    data: null,
    openWithClick: {
      type: Boolean,
      default: false, // opens with hover
    },
    condense: null,
    isSubscribed: { type: Boolean, default: false },
  },
  data: function () {
    return {
      // dialog: false,
      showMenu: false,
      bigScreenSize: 1200,
    };
  },
  methods: {
    makeStyle(item) {
      let rtn = "";
      let color = item.color ? item.color : null;
      if (color) rtn = "background-color: " + color;
      return rtn;
    },
    itemAuth(item) {
      return !item.pro || (item.pro && this.isSubscribed);
    },
    itemDisable(item) {
      // don 't disable if
      return !this.itemAuth(item) && !item.moreInfo;
    },
    itemTo(item) {
      let to = item.to;
      if (this.itemAuth(item)) return to;
      if (!this.itemAuth(item) && item.moreInfo) to = item.moreInfo;
      return to;
    },
    itemHref(item) {
      let href = item.href;
      if (this.itemAuth(item)) return href;
      if (!this.itemAuth(item)) href = null;
      return href;
    },

    doAction(item) {
      // console.log(action);
      let action = item?.action;
      if (!action) return;
      if (this.itemAuth(item) && action == "showRxSearch") {
        this.$store.dispatch("showRxSearch", true);
        this.$router.push("/cls#inputRx=1").catch(() => {});
      }
    },
  },
  computed: {
    isPro: function () {
      return this.data?.pro;
    },
  },
};
</script>

<style scoped>
.scrollHeight {
  max-height: 90vh;
}
</style>
