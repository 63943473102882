<template>
  <div>
    <v-list>
      <!-- show the login if not logged in -->
      <v-list-item v-if="!$vuetify.breakpoint.mdAndUp && !userObject">
        <v-list-item-icon> <v-icon>mdi-account</v-icon></v-list-item-icon>
        <v-list-item-content>
          <user-login :menuItem="true"></user-login>
        </v-list-item-content>
      </v-list-item>

      <!-- items that are hidden on large screens -->
      <v-list-item
        class="d-flex d-sm-none"
        v-for="(navItem, index) in filteredNavData"
        :key="index + 'x'"
        :to="itemPathForUser(navItem)"
        @click="navItem.action ? doAction(navItem) : null"
      >
        <v-list-item-icon>
          <img v-if="navItem.image" :src="navItem.image" class="iconSize" />
          <v-icon
            v-if="navItem.icon"
            :color="navItem.iconColor ? navItem.iconColor : 'primary'"
            >mdi-{{ navItem.icon }}</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ navItem.label }} </v-list-item-title>
          <v-list-item-subtitle v-if="navItem.subtitle">{{
            navItem.subtitle
          }}</v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="navItem.pro && !isSubscribed"
            class="blue--text"
          >
            Learn More
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- the remaining items will always be displayed in the hamburger  -->
      <v-list-item
        v-for="(item, index) in itemsToShow"
        :key="index"
        @click="goTo(item)"
      >
        <v-list-item-icon>
          <img v-if="item.image" :src="item.image" class="iconSize" />
          <v-icon
            v-if="item.icon"
            :color="item.iconColor ? item.iconColor : 'primary'"
            >{{ item.icon }}</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- show the user info IF mobile and IF logged in -->
      <v-list-item v-if="!$vuetify.breakpoint.mdAndUp && userObject">
        <v-list-item-icon> <v-icon>mdi-account</v-icon></v-list-item-icon>
        <v-list-item-content>
          <user-login :menuItem="true"></user-login>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
// import Name from "../dir/file";
import NavigationOptions from "@/assets/js/NavigationOptions";
import UserMixin from "@/assets/js/UserMixin";

export default {
  name: "HamburgerMenu",

  components: {
    // SegmentedButton,
    UserLogin: () => import("@/components/universalStuff/UserLogin"),
  },
  props: { navData: null },
  mixins: [NavigationOptions, UserMixin],
  data: function () {
    return {
      items: [
        {
          label: "Join EyeDock Pro",
          subtitle: "Get access to Pro features",
          image: "http://www.eyedock.com/images/menu_icons/pro_icon.svg",
          // icon: "mdi-professional-hexagon",
          iconColor: "orange",
          path: "/documentation/about-eyedock-pro",
          onlyUnsubscribed: true,
        },
        {
          label: "EyeDock Documentation",
          subtitle: "FAQs, how-to's, disclaimers, etc.",
          image: null,
          icon: "mdi-book-open-page-variant-outline",
          path: "/documentation",
        },
        {
          label: "Contact Us",
          subtitle:
            'Report a concern, make a feature request, or just say "hi".',
          image: null,
          icon: "mdi-at",
          sendEmail: true,
        },
      ],
    };
  },
  methods: {
    itemPathForUser(item) {
      let auth = !item.pro || (item.pro && this.isSubscribed);
      let to = item.to;
      if (!auth && item.moreInfo) to = item.moreInfo;
      return to;
    },
    goTo(item) {
      // console.log(item);
      let external = item.path?.includes("http");
      if (item.sendEmail) {
        // if (this.userObject) this.$zendesk.identify(this.userObject);
        this.$zendesk.show();
        this.$zendesk.open();

        // window.open("mailto:support@eyedock.com", "_blank");
      } else if (external) {
        window.location.href = item.path;
      } else {
        this.$router.push({ path: `${item.path}` }).catch(() => {});
      }
    },
    doAction(item) {
      // console.log(action);
      let action = item?.action;
      if (!action) return;
      if (this.itemAuth(item) && action == "showRxSearch") {
        this.$store.dispatch("showRxSearch", true);
        this.$router.push("/cls#inputRx=1").catch(() => {});
      }
    },
    itemAuth(item) {
      return !item.pro || (item.pro && this.isSubscribed);
    },
  },
  computed: {
    isDesktop: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    itemsToShow: function () {
      // let isDesktop = this.$vuetify.breakpoint.mdAndUp;
      let isPro = this.isSubscribed;
      let isDesktop = this.isDesktop;
      return this.items.filter((item) => {
        let hideOnDesktop = item.hideOnDesktop ?? false;
        if (item.onlyUnsubscribed && isPro) return false;
        return isDesktop && hideOnDesktop ? false : true;
      });
    },
    filteredNavData: function () {
      // console.log(NavigationOptions.navTree);
      if (!this.navData) return null;
      let rtn = this.navData.filter((item) => {
        // console.log(item.only, this.$vuetify.breakpoint.smAndUp);
        if (item.pro && !this.isLoggedIn) return false;
        if (!item.only) return true;
        if (item.only == "sm" && !this.$vuetify.breakpoint.smAndUp)
          return false;
        if (item.only == "xs" && this.$vuetify.breakpoint.smAndUp) return false;
        return true;
      });

      return rtn;
    },
    userObject: function () {
      let suo = this.storedUserObj;
      return suo
        ? {
            name: `${suo.name} (${suo.login})`,
            email: suo?.email,
          }
        : null;
    },
  },
  mounted() {
    this.$zendesk.$on("loaded", () => {
      if (this.userObject) this.$zendesk.identify(this.userObject);
    });
  },
};
</script>

<style scoped>
.iconSize {
  width: 25px;
}
</style>
