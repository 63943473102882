<template>
  <div class="ma-1 ma-sm-6">
    <div class="text-center blue--text text--darken-3 mb-4">
      <div class="text-h3">Welcome to EyeDock</div>
      <div class="caption my-2">A resource for eye care professionals</div>
    </div>
    <div v-if="partner">
      <page
        :articleSlug="'partner-' + partner"
        :key="'partner-' + partner"
        :showHeader="false"
        :showSubscribeButton="false"
      ></page>
    </div>
    <div>
      <page
        articleSlug="home-page"
        key="home-page"
        :showHeader="false"
        :showSubscribeButton="true"
      ></page>
    </div>
    <div v-if="!isSubscribed">
      <page
        articleSlug="about-eyedock-pro"
        key="about-eyedock-pro"
        :showSubscribeButton="false"
      >
      </page>
    </div>
    <!-- <features-cards></features-cards> -->
    <!-- <features-grid></features-grid> -->
  </div>
</template>

<script>
import Page from "@/components/pagesStuff/Page";
// import FeaturesGrid from "@/components/pagesStuff/FeaturesGrid";
import UserMixin from "@/assets/js/UserMixin";
// import FeaturesCards from "@/components/pagesStuff/FeaturesCards";
// import Name from "../dir/file";

export default {
  name: "Home",
  components: {
    Page,
    // FeaturesGrid,
    //   FeaturesCards
  },
  mixins: [UserMixin],
  props: {},
  data: function () {
    return { partner: null };
  },
  methods: {},
  computed: {},
  mounted() {
    this.partner = this.$route?.params?.partner;
  },
};
</script>

<style scoped>
/* th,
td {
  padding: 2px 6px;
  vertical-align: top;
}
.cardTitle {
  background-color: #cee9fb;
} */
</style>
