import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import VueAnalytics from "vue-analytics";
import VueGtag from "vue-gtag";
import VueCookies from "vue-cookies";
import VueResource from "vue-resource";
import VueMeta from "vue-meta";

// import Editor from "v-markdown-editor"; // do I really want this to be global?
// import "v-markdown-editor/dist/v-markdown-editor.css";
import Zendesk from "@dansmaculotte/vue-zendesk";

// import "./registerServiceWorker";

require("./assets/css/global.css");

Vue.use(VueResource);
// Vue.use(Editor);

Vue.use(VueCookies);
Vue.$cookies.config("90d");

// https://vue-meta.nuxtjs.org/guide/#package-manager
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

// Configuration VueAnalytics
// Vue.use(VueAnalytics, {
//   id: "UA-4108420-1",
//   router,
// });

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-LP66Z51D8B",
      params: {
        send_page_view: false,
      },
    },
  },
  router
);

Vue.use(Zendesk, {
  key: "cc188fd3-cb12-46ab-ba86-493fcdf8ac6a",
  disabled: false,
  hideOnLoad: false,
  settings: {
    webWidget: {
      color: {
        theme: "#c8ddf0",
      },
    },
  },
});

Vue.config.productionTip = false;

Vue.prototype.$hostname = "https://www.eyedock.com";
// Vue.prototype.$hostname = "http://localhost:8888";

Vue.filter("truncate", function (value, size) {
  if (!value) return null;
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "...";
});

Vue.filter("titleize", function (value) {
  if (!value) return null;
  value = value.replaceAll("_", " ");
  return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
  // return value.replace(/(?:^[\s\-'"])\S/g, (x) => x.toUpperCase());
});

Vue.filter("currency", function (value) {
  if (isNaN(value)) return "  ";
  let num = parseFloat(value);
  let prefix = num < 0 ? "-" : "";
  return `${prefix} $${Math.abs(num).toFixed(2)}`;
});

Vue.filter("eyeText", function (value) {
  if (!value) return "";
  return value == "od" ? "Right" : "Left ";
});

Vue.filter("eyeLetter", function (value) {
  if (!value) return "";
  return value == "od" ? "R" : "L";
});

// Vue.prototype.$baseUrl = window.location;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
