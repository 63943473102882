export default {
  props: {},
  data: function() {
    return {};
  },
  methods: {
    medTypeSummary(str) {
      str = str.toLowerCase();
      let rtn = {};
      rtn.isOtc = str.includes("otc");
      rtn.isRx = str.includes("prescription");
      return rtn;
    },
    medBrandSummary(is_brand) {
      let brandInt = parseInt(is_brand);
      let rtn = {};
      rtn.isBrand = brandInt == 1;
      rtn.isGeneric = brandInt == 0;
      return rtn;
    },
    medFormSummary(str) {
      str = str.toLowerCase();
      let rtn = {};
      rtn.isSolution = str.includes("solution");
      rtn.isSuspension = str.includes("suspension");
      rtn.isEmulsion = str.includes("emulsion");
      rtn.isLiquid = str.includes("liquid");
      rtn.isDrop =
        rtn.isSolution ||
        rtn.isEmulsion ||
        rtn.isSuspension ||
        rtn.isLiquid ||
        str.includes("drop");
      rtn.isUng = str.includes("ointment");
      rtn.isGel = str.includes("gel");
      rtn.isInjection = str.includes("inject");
      rtn.isSpray = str.includes("spray");
      if (rtn.isInjection) rtn.isDrop = false;
      if (rtn.isSpray) rtn.isDrop = false;

      return rtn;
    },
    /*
    isRx(str) {
      return str.includes("prescription");
    },
    isOTC(str) {
      return str.includes("otc");
      },
   
    isSolution(str) {
      str.includes("solution");
    },
    isSuspension(str) {
      str.includes("suspension");
    },
    isEmulsion(str) {
      str.includes("emulsion");
    },
    isDrop(str) {
      let is_solution = str.includes("solution");
      let is_drop = str.includes("drop");
      let is_suspension = str.includes("suspension");
      let is_emulsion = str.includes("emulsion");
      let is_liquid = str.includes("liquid");
      return (
        is_solution || is_drop || is_emulsion || is_suspension || is_liquid
      );
    },
    isUng(str) {
      return str.includes("ointment");
    },
    isGel(str) {
      return str.includes("gel");
    },
    isInject(str) {
      return str.includes("inject");
    },
    */
  },
};
