<template>
  <div
    class="d-flex flex-row"
    :class="
      $vuetify.breakpoint.name == 'sm'
        ? 'ipadWidth marginX'
        : 'groupWidth marginX'
    "
  >
    <v-combobox
      v-model="selected"
      :loading="loading"
      :items="dynamicItems"
      :search-input.sync="searchInput"
      item-text="label"
      cached-items
      no-filter
      class="mx-0"
      flat
      hide-no-data
      hide-details="auto"
      hide-selected
      :label="searchLabel"
      solo
      outlined
      dense
      clearable
      return-object
      :autofocus="true"
      ref="theTextbox"
      @keyup.enter="doSearchAll()"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex flex-row align-center fullWidth">
          <div
            v-if="item.image && item.image != 'null'"
            class="mr-1 blue--text darken-4"
            :class="isMobile ? 'moduleNameDiv_m' : 'moduleNameDiv'"
          >
            <v-img
              :src="imageProductPath(item) + item.image"
              max-height="35"
              max-width="35"
              contain
            ></v-img>
          </div>
          <v-icon v-else-if="item.module == 'IMG'" class="mr-2" color="primary"
            >mdi-image</v-icon
          >

          <div
            v-else-if="onlyModule == 'ALL'"
            class="overline mr-1 blue--text darken-4"
            :class="isMobile ? 'moduleNameDiv_m' : 'moduleNameDiv'"
          >
            {{ item.module }}
          </div>
          <v-list-item-content class="listElement">
            <v-list-item-title class="pr-3">
              {{ item.name }}
              <span v-if="item.discontinued == '1'" class="caption red--text"
                >(discontinued)</span
              >
            </v-list-item-title>
            <v-list-item-subtitle
              class="pr-3 subtle font-italic"
              v-if="
                item.subtitle &&
                item.subtitle.toLowerCase() != item.name.toLowerCase()
              "
            >
              {{ item.subtitle }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              class="pr-3 subtle font-italic"
              v-if="item.groups && item.groups.company"
            >
              {{ item.groups.company }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              class="pr-3 subtle font-italic"
              v-if="item.module == 'MED' && item.type == 'COMPANY'"
            >
              Medications from {{ item.name }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              class="pr-3 subtle font-italic"
              v-if="item.module == 'CL' && item.type == 'COMPANY'"
            >
              Soft contact lenses from
              {{ item.name }}</v-list-item-subtitle
            >

            <v-list-item-subtitle
              class="pr-3 subtle font-italic"
              v-if="item.module == 'RGP' && item.type == 'COMPANY'"
            >
              Specialty contact lenses from
              {{ item.name }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              class="pr-3 subtle font-italic"
              v-if="item.module == 'CL' && item.type == 'COMPONENT'"
            >
              Lenses with polymer
              {{ item.name }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              class="pr-3 subtle font-italic"
              v-if="item.module == 'MED' && item.type == 'COMPONENT'"
            >
              Medications containing
              {{ item.name }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              class="pr-3 subtle font-italic"
              v-if="item.module == 'RGP' && item.type == 'COMPONENT'"
            >
              GP Material</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-spacer class="d-none d-sm-flex"></v-spacer>
          <div class="typeIconDiv d-none d-sm-flex flex-row">
            <div v-if="item.module == 'RX'">
              <v-img
                :src="path.crosshairs"
                width="26"
                class="mx-1"
                :style="`transform: rotate(${-item.angle}deg);`"
              ></v-img>
            </div>
            <v-img
              v-if="item.module == 'BC' && item.groups.category"
              :src="
                path.iconSrc + item.groups.category.toLowerCase() + '_icon.svg'
              "
              max-width="28"
              class="mx-1"
            >
            </v-img>
            <!-- <div v-if="item.module == 'BC'">{{ item.groups.category }}</div> -->
            <span v-if="item.type == 'COMPONENT'">
              <span v-if="item.module == 'CL' || item.module == 'MED'">
                <v-icon small class="mx-1">mdi-flask</v-icon>
              </span>
            </span>

            <span v-if="item.type == 'COMPANY'">
              <v-icon small class="mx-1">mdi-factory</v-icon>
              <!-- <span class="ml-1 caption"
              >{{ item.module == "CL" ? "(CLs)" : "(Meds)" }}
            </span> -->
            </span>

            <span v-else-if="item.type == 'PRODUCT'">
              <div v-if="item.module == 'CL'" class="d-flex flex-row">
                <div v-if="item.groups && item.groups.type">
                  <v-img
                    :src="iconForClType(item.groups.type)"
                    width="20"
                    class="mx-1"
                  ></v-img>
                </div>

                <div v-if="item.groups && item.groups.replace">
                  <v-img
                    :src="iconForClReplace(item.groups.replace)"
                    width="20"
                    class="mx-1"
                  ></v-img>
                </div>
              </div>

              <v-img
                v-else-if="item.module == 'RGP'"
                :src="iconForRgpType(item.groups.type)"
                width="20"
                class="mx-1"
              ></v-img>

              <v-icon
                v-else-if="item.module == 'ORAL'"
                small
                color="orange"
                class="mx-1"
                >mdi-pill</v-icon
              >
              <v-icon v-else-if="item.module == 'DZ'" color="red" class="mx-1"
                >mdi-eye</v-icon
              >
              <v-icon
                v-else-if="item.module == 'REF'"
                color="green"
                class="mx-1"
                >mdi-table</v-icon
              >
              <v-icon
                v-else-if="item.module == 'SITE'"
                color="blue"
                class="mx-1"
                >mdi-web</v-icon
              >

              <med-icon-block
                v-else-if="item.module == 'MED'"
                class="mx-1"
                :listing="item.groups"
                :row="true"
                :showLabels="false"
              ></med-icon-block>

              <div v-else-if="item.module == 'ICD10'">
                <div class="overline orangeText pl-2">
                  {{ item.identifier }}
                </div>
              </div>
            </span>
          </div>
        </div>
      </template>
      <template
        #append
        v-if="(!onlyModule || onlyModule == 'ALL') && !hideModuleOptions"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="" x-small depressed rounded>
              <span class="d-none d-sm-inline">{{
                moduleOptions[selectedModuleOption].short
              }}</span>
              <v-icon>mdi-menu-down</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item
              v-for="(option, key) in moduleOptions"
              :key="key"
              @click="changeModuleToSearch(key)"
              :disabled="!modulesKeysToInclude.includes(key)"
            >
              <v-list-item-title class="d-flex flex-row">
                <div class="checkWidth mr-2">
                  <v-icon v-if="key == selectedModuleOption">mdi-check</v-icon>
                </div>
                {{ option.name }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-combobox>
  </div>
</template>

<script>
// import Name from "../dir/file";
import ApiServices from "@/services/ApiServices";
import MedIconBlock from "@/components/medStuff/MedIconBlock.vue";
import RxMethods from "@/assets/js/cls/RxMethods";
// import Slugify from "slugify";

export default {
  name: "AutocompleteAll-2",
  components: {
    MedIconBlock,
  },
  mixins: [RxMethods],
  props: {
    hideModuleOptions: {
      type: Boolean,
      default: false,
    },
    action: { type: String, default: "route" }, // defaults to routing to a product page.  "object" will just emit the selected item as - you guessed it - an object
    onlyModule: { type: String, default: "ALL" }, // to limit searches to one module, supply a key from moduleOptions (eg "CL", "MED", etc)
    onlyType: { type: String, default: null }, // to only search for one type. "PRODUCT", "CATEGORY", "COMPANY", or "COMPONENT". eg) for CL module use "PRODUCT" if you don't want companies
    hideSubscriberContent: { type: Boolean, default: false },
    giveFocus: null,
    externalText: null,
    canSearchAll: null, // sends text to search-all (doesn't require selecting a specific item)
    moreParams: null,
    label: null,
    marginX: { type: String, default: "mx-4" },
  },
  data: function () {
    return {
      loading: false,
      // rxItems: null,
      items: [],
      groups: {},
      searchInput: null,
      selected: null,
      path: {
        type: "/images/icons/lensTypes/",
        replace: "/images/icons/replace/",
        crosshairs: "/images/icons/lensTypes/crosshairs.svg",
        eyedock: "https://www.eyedock.com",
        iconSrc: "https://www.eyedock.com/images/menu_icons/",
        lens_images:
          "https://www.eyedock.com/modules/Lenses/pnimages/lens_images/",
        meds_images: "https://www.eyedock.com/fdaMeds/media/",
        rgp_images: "https://www.eyedock.com/images/rgp_media/",
        img_images: "https://www.eyedock.com/images/educational_images/",
        cl_product_images: "https://www.eyedock.com/images/cl_products/",
        rxURL: "/cls/rx#refractionOD=",
      },
      selectedModuleOption: "ALL",
      selectedType: null, //"PRODUCT", "CATEGORY", "COMPANY", "COMPONENT"
      nonSubscriberKeys: ["ALL", "CL", "MED", "ORAL", "IMG", "REF", "SOLN"],
      moduleOptions: {
        ALL: { name: "All Sections", short: "All" },
        ICD10: { name: "ICD10 Codes", short: "ICD" },
        BC: { name: "Billing Codes", short: "Codes" },
        CL: { name: "Soft Contact Lenses", short: "CLs" },
        RGP: { name: "GP Lenses", short: "GPs" },
        SOLN: { name: "CL Solutions", short: "Solutions" },
        MED: { name: "Ophthalmic Medications", short: "Meds" },
        ORAL: {
          name: "Oral/Systemic Meds (related to eye care)",
          short: "Orals",
        },
        DZ: { name: "Ocular Conditions", short: "Dz" },
        REF: { name: "Clinical References", short: "Refs" },
        IMG: { name: "Educational Images", short: "Images" },

        RX: {
          name: "Refraction / Power",
          short: "Rx",
        },
        SITE: {
          name: "EyeDock Features",
          short: "Page",
        },
      },
    };
  },
  methods: {
    isMobile: function () {
      return !this.$vuetify.smAndUp;
    },
    changeModuleToSearch(module) {
      this.selectedModuleOption = module;
      this.querySelections();
    },
    doSearchAll() {
      if (!this.canSearchAll) return;
      let term = this.searchInput.replace(/\s+/g, " "); // consolidate spaces
      this.$refs.theTextbox.blur();
      this.$nextTick(() => {
        this.searchInput = null;
        this.selected = null;
      });

      let uri = encodeURIComponent(term);

      this.$router.push({ path: "/search-all?q=" + uri }).catch(() => {});
      this.$emit("dismiss");
      // console.log(term);
    },
    querySelections() {
      let term = this.searchInput;
      if (term && term != "" && this.selectedModuleOption != "RX") {
        this.loading = true;
        ApiServices.searchAll(
          term,
          this.selectedModuleOption,
          this.selectedTypeOption,
          this.moreParams
        )
          .then((response) => {
            this.items = response.data.items;
            this.groups = response.data.meta.groups;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    iconForClType(cl_type) {
      let icon = cl_type.toLowerCase();
      return this.path.type + icon + "_icon_active.svg";
    },
    iconForRgpType(type) {
      let icon = "rgp";
      if (type == "scleral") icon = "scleral";
      if (icon == "ortho-k") icon = "ortho-k";
      return this.path.iconSrc + `${icon}_icon.svg`;
    },
    iconForClReplace(replace) {
      let icon = `replace_${replace}`;
      return this.path.replace + icon + "_active.svg";
    },
    iconForBillingCode(cat) {
      if (!cat) return null;
      let img = this.iconSrc + cat.toLowerCase() + "_icon.svg";
      // console.log(img);
      return img;
    },

    searchRouter(obj) {
      this.$nextTick(() => {
        this.searchInput = null;
        this.selected = null;
      });
      if (this.action == "route") {
        this.doRouter(obj);
      } else if (this.action == "object") {
        this.$emit("selected", obj);
      }
      // console.log(obj);
      this.$emit("dismiss");
    },
    doRouter(obj) {
      if (obj.module == "CL" && obj.type != "COMPONENT") {
        obj.url = obj.type == "COMPANY" ? "/cls/company/" : "/cls/details/";

        let identifier = obj.slug && obj.slug != "null" ? obj.slug : obj.itemId;
        obj.url += identifier;
        if (obj.type == "PRIVATE") obj.url += "#privateId=" + obj.itemId;
      }
      if (obj.url.includes("http")) {
        window.location.href = obj.url;
      } else {
        this.$router.push({ path: obj.url }).catch(() => {});
      }
    },
    imageProductPath(item) {
      let path = "";
      if (item.module == "CL") {
        if (item.type == "PRODUCT" || item.type == "PRIVATE")
          path = this.path.lens_images;
      }
      if (item.module == "MED" || item.module == "ORAL") {
        if (item.type == "PRODUCT") path = this.path.meds_images;
      }
      if (item.module == "RGP") {
        if (item.type == "PRODUCT") path = this.path.rgp_images;
      }
      if (item.module == "IMG") path = this.path.img_images;
      if (item.module == "SOLN") path = this.path.cl_product_images;

      if (item.image.includes("/images/")) path = "http://www.eyedock.com";

      return path;
    },
  },
  computed: {
    moduleKeys: function () {
      return Object.keys(this.moduleOptions);
    },
    itemsByModule: function () {
      if (!this.items) return null;
      let ibm = {};
      let namesInModule = {}; // keep track of names so we don't have duplicates
      this.items.forEach((row) => {
        const moduleKey = row.module;
        if (!ibm[moduleKey]) {
          ibm[moduleKey] = [];
          namesInModule[moduleKey] = [];
        }
        if (!namesInModule[moduleKey].includes(row.name)) {
          ibm[moduleKey].push(row);
          namesInModule[moduleKey].push(row.name);
        }
      });
      return ibm;
    },
    rxItems: function () {
      if (!this.includeRX) return null;
      let input = this.searchInput;
      // console.log(input);
      let rxItems = null;
      let rxObj = this.parseRxString(input);
      let isValidRx = this.isValidRx(rxObj);
      // console.log(input + ": " + isValidRx);
      if (isValidRx && !this.isPlano(rxObj)) {
        let name = `${this.prettyString(rxObj)}`;
        let angle = this.axisM(rxObj);
        let url =
          this.path.rxURL +
          encodeURIComponent(this.prettyString(rxObj).replace(/ /g, ""));
        rxItems = [
          {
            name: name,
            module: "RX",
            subtitle: ` (Vertex and find soft CLs)`,
            url: url,
            angle: angle,
          },
          {
            name: name,
            module: "RX",
            subtitle: ` (Find this power CL)`,
            url: url + "&vertex=0",
            angle: angle,
          },
        ];
      }
      return rxItems;
    },
    dynamicItems: function () {
      if (!(this.items || this.rxItems)) return null;
      //let items = this.items.slice(); // to copy by value (not by reference)
      let result = [];
      let ibm = this.itemsByModule;
      Object.keys(this.groups).forEach((moduleKey) => {
        if (this.moduleOptions[moduleKey]) {
          result.push({ header: this.moduleOptions[moduleKey].name });
          result.push(...ibm[moduleKey]);
        }
      });

      // console.log(this.modulesKeysToInclude);

      // todo - add a "deep search" option for each component? (only if all modules being searched)

      if (this.rxItems && this.includeRX) {
        result.push({ header: "Optics & Calculations" });
        this.rxItems.forEach((row) => {
          result.push(row);
        });
      }

      if (this.deepSearches && this.includeEverything) {
        result.push({ header: "Search Everything" });
        result.push(...this.deepSearches);
      }

      if (!result || result.length < 1) return null;
      return result;
    },
    deepSearches: function () {
      let rtn = [];
      if (!this.searchInput) return null;
      let uri = encodeURIComponent(this.searchInput);
      // rtn.push({
      //   name: "Search CLs",
      //   module: "SEARCH",
      //   kind: "CL",
      //   url: "/cls/search?q=" + uri,
      // });
      // rtn.push({
      //   name: "Search Ocular Conditions",
      //   module: "SEARCH",
      //   kind: "DZ",
      //   url: "/ocular-conditions/search/" + uri,
      // });
      rtn.push({
        name:
          "Search Everything for '" + this.searchInput.substring(0, 15) + "..'",
        module: "ALL",
        kind: "ALL",
        url: "/search-all?q=" + uri,
      });
      return rtn;
    },
    searchLabel: function () {
      if (this.label) return this.label;
      return (
        "Search " +
        this.moduleOptions[this.selectedModuleOption].name.toLowerCase()
      );
    },
    modulesKeysToInclude: function () {
      // if (this.onlyModule && this.moduleOptions[this.onlyModule])
      //   return [this.onlyModule];
      // if (this.selectedModuleOption != "ALL")
      //   return [this.selectedModuleOption];
      return this.hideSubscriberContent
        ? this.nonSubscriberKeys
        : this.moduleKeys;
    },
    includeRX: function () {
      return (
        this.modulesKeysToInclude.includes("RX") &&
        (this.selectedModuleOption == "RX" ||
          this.selectedModuleOption == "ALL")
      );
    },
    includeEverything: function () {
      return (
        this.modulesKeysToInclude.includes("ALL") &&
        (this.selectedModuleOption == "ALL" ||
          this.selectedModuleOption == "RX")
      );
    },
  },
  watch: {
    searchInput(val) {
      //console.log(val);
      // if (typeof val === "object") {
      //   this.searchInput = null;
      //   return;
      // }
      // if (val) this.appendRxFilters(val);
      val && val !== this.selected && this.querySelections();
    },
    selected(obj) {
      if (obj && obj.url) this.searchRouter(obj);
    },

    giveFocus(val) {
      if (val) this.$nextTick(() => this.$refs.theTextbox.$el.focus());
    },
    externalText(val) {
      this.searchInput = val;
    },
  },
  mounted() {
    if (this.onlyModule && this.moduleOptions[this.onlyModule])
      this.selectedModuleOption = this.onlyModule;
    if (this.onlyType) this.selectedTypeOption = this.onlyType;
    if (this.externalText) this.searchInput = this.externalText;
  },
};
</script>

<style scoped>
.typeIconDiv {
  width: 90px;
}
.moduleNameDiv {
  min-width: 70px;
}
.moduleNameDiv_m {
  min-width: 45px;
}
.fullWidth {
  width: 100%;
  max-width: 600px;
}
.listElement {
  min-width: 300px;
}
.groupWidth {
  width: 300px;
  max-width: 90%;
}
.ipadWidth {
  width: 230px;
}
.checkWidth {
  width: 26px;
}
.orangeText {
  color: #c38348;
}
</style>
