import ApiServices from "@/services/ApiServices";

export default {
  data: function () {
    return {
      trialSubscriptionId: "1",
      recurringSubscriptionId: "7",
      yearlySubscriptionId: "6",
      monthlySubscriptionId: "12",
      expiringSoonDays: 10,
      checkActiveEveryXdays: 7, //days
      adminGroupNum: "2",
    };
  },
  methods: {
    setUserData(data) {
      if (!data) return null;
      //   let obj = null;
      //   if (data) obj = { name: username };
      localStorage.setItem("eyedockUserData", JSON.stringify(data));
      this.$store.dispatch("setUserData", data);
      this.setLocalIsSubscribed();
    },
    setLocalIsSubscribed() {
      localStorage.setItem("isSubscribed", this.isSubscribed);
    },
    logoutUser() {
      localStorage.removeItem("isSubscribed");
      localStorage.removeItem("eyedockUserData");
      this.$store.dispatch("setUserData", null);
    },
    resetLastAuthenticated() {
      let data = this.storedUserObj;
      if (!data) return;
      data.lastAuthenticated = this.formatDate();
      this.setUserData(data);
    },

    formatDate(date = null) {
      let d = date ? new Date(date) : new Date();
      let month = (d.getMonth() + 1).toString();
      let day = d.getDate().toString();
      let year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    },
    periodicLoginCheck() {
      let u = this.stored_login;
      if (!u) {
        this.logoutUser();
        return;
      }
      ApiServices.loginWithUser(u)
        .then((response) => {
          let result = response.data;
          console.log(result);
          let ok = result.ok;
          this.error = !ok ? result.msg : null;
          if (ok && result.login) {
            result.lastAuthenticated = this.formatDate();
            this.setUserData(result);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = "An API error occurred";
        });
    },
  },
  computed: {
    storedUserObj: function () {
      return this.$store.state.userData;
    },
    stored_login: function () {
      return this.storedUserObj?.login;
    },
    stored_user_id: function () {
      return this.storedUserObj?.user_id;
    },
    stored_user_key: function () {
      return this.storedUserObj?.user_key;
    },
    auth_credentials: function () {
      // console.log("auth_credentials");
      let rtn = { user_id: null, user_key: null, login: null };
      if (this.stored_user_id) rtn.user_id = this.stored_user_id;
      if (this.stored_user_key) rtn.user_key = this.stored_user_key;
      if (this.stored_login) rtn.login = this.stored_login;
      if (!rtn.user_id || !rtn.user_key || !rtn.login) return null;
      return rtn;
    },
    auth_paramstring: function () {
      if (!this.stored_user_id || !this.stored_user_key) return null;
      return `user_id=${this.stored_user_id}&user_key=${this.stored_user_key}&login=${this.stored_login}`;
    },
    subscriptions: function () {
      return this.storedUserObj?.subscriptions;
    },

    subscriptionIds: function () {
      if (!this.subscriptions) return null;
      return Object.keys(this.subscriptions);
    },
    subscriptionExpirations: function () {
      if (!this.subscriptions) return null;
      let dates = Object.values(this.subscriptions).sort();
      return dates;
    },
    latestExpiration: function () {
      if (!this.subscriptionExpirations || !this.subscriptionExpirations.length)
        return null;
      return this.subscriptionExpirations.at(-1);
    },
    activeSubscription: function () {
      if (!this.latestExpiration) return null;
      let date1 = Date.parse(this.latestExpiration);
      let date2 = new Date();
      let diff = Math.ceil(date2 - date1) / (1000 * 60 * 60 * 24);
      return diff;
    },
    isLoggedIn: function () {
      return this.storedUserObj ? true : false;
    },
    isSubscribed: function () {
      return this.activeSubscription && Math.abs(this.activeSubscription) > 0;
    },
    activeSubscriptionToGroup: function () {
      // do stuff
    },
    isAdmin: function () {
      let groups = this.storedUserObj?.groups;
      if (!groups) return false;
      return groups.includes(this.adminGroupNum);
    },
    renewingSubscription: function () {
      if (!this.activeSubscription) return false;
      let rtn = false;
      if (this.subscriptionIds.includes(this.recurringSubscriptionId))
        rtn = true;
      if (this.subscriptionIds.includes(this.monthlySubscriptionId)) rtn = true;
      return rtn;
    },
    yearlyExpiresSoon: function () {
      if (!this.activeSubscription) return null;
      if (this.renewingSubscription) return null;
      return Math.abs(this.activeSubscription) < this.expiringSoonDays;
    },
    dueForLoginCheck: function () {
      let lastCheck = this.storedUserObj?.lastAuthenticated;
      if (!lastCheck) return true;
      let checkedDate = Date.parse(lastCheck);
      let today = new Date();
      let diff = Math.ceil(today - checkedDate) / (1000 * 60 * 60 * 24);
      return Math.abs(diff) >= this.checkActiveEveryXdays;
    },
  },
  watch: {
    isLoggedIn() {
      this.$nextTick(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      });
    },
  },
  mounted() {
    if (localStorage.getItem("eyedockUserData")) {
      let obj = JSON.parse(localStorage.getItem("eyedockUserData"));
      this.$store.dispatch("setUserData", obj);
      if (this.dueForLoginCheck) this.periodicLoginCheck();
    }
  },
};
